// Font Extends 
%font-normal-base {
  font-family:"Cabrito Sans Subset", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
%font-demi-base {
  font-family:"Cabrito Sans Subset", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;  
  font-weight:normal;
}
%font-bold-base {
  font-family:"Cabrito Sans Subset", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;  
  font-weight:normal;
  text-transform:uppercase;
}
%font-normal {
  font-family:"Cabrito Sans";
  font-weight:400;
}
%font-demi {
  font-family:"Cabrito Sans";
  font-weight:700;
}
%font-bold {
  font-family:"Cabrito Sans";
  font-weight:900;
}
%tnum {
  font-family: "Cabrito Sans Subset";
  font-variant-numeric: tabular-nums;
  -moz-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}

// Other Extends
%sr-only {
/* items which are visually hidden but are available for screen
 * readers based on the recommendations:;
 * https://www.w3.org/TR/2012/NOTE-WCAG20-TECHS-20120103/C7
 * https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
 */
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}
%menu-list {
  display:flex;
  flex-wrap:wrap;
  list-style-type:none;
  padding:0;

  li {
    list-style:none;
    &:before {
      content: "|";
    }
    &:first-child {
      &:before {
        content: none;
      }
      a {
        margin-left:0;
      }
    }
    a {
      margin-left:0.5em;
      margin-right:0.5em;
    }
  }
} 
%no-decoration-link {
  text-decoration:none;
  &:hover {
    text-decoration:underline;
  }
}
%no-link-icon {
  background-image:none;
  padding-right:0;  
}
%clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
  } 
  &::after {
    clear: both;
  }  
}
@mixin button($link_color: $color_near_white, $background_color: $color_link, $hover_color: $color_link, $hover_background_color: $color_secondary_medium, $active_background_color: $color_near_white, $border_color: transparent, $btn_padding: $width_gutter ) {
  display:inline-block;
  padding:($btn_padding/5) $btn_padding;
  text-decoration: none;
  border: 1px solid $border_color;
  &:link, &:visited {
    background-color: $background_color;
    color: $link_color;
  }
  &:hover {
    color:$hover_color;
    background-color: $hover_background_color;
  }
  &:active {
    color:$hover_color;
    background-color: $active_background_color;
  }
  &.button-big {
    // may be taller or wider, use absolute positioning
    // for anchor
    display:block;
  }
}
@mixin header-bars($color: $color_near_white, $padding: 0.3em, $margins: $width_gutter * .3) {
  /* developed based on some solutions discussed at
   * https://css-tricks.com/line-on-sides-headers/
   */
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  margin-left:$margins;
  margin-right:$margins;
  &:before, 
  &:after {
    border-top: 1px solid $color;
    content: '';
    position: relative;
    margin-top: 0.4em;
    width: 50%;
  }
  &:before { right: $padding; }
  &:after { left: $padding; }
}
