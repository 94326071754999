// Variables
$color_primary: rgb(0,38,58);   // #00263a
$color_primary_dark: $color_primary;
$color_primary_med_dark: #375767;
$color_primary_med: #49768e;
$color_primary_med_light: rgb(122,153,172); // #7a99ac
$color_primary_light: #bed2dd;

$color_secondary_dark: #4e6c7d;
$color_secondary_med_dark: $color_primary_med_light;
$color_secondary_medium: #bdd2de;
$color_secondary_light: #f1f5f7;

$color_tertiary: rgb(17,55,75); // #11374b

$color_near_white: #fbfcfc;

$color_link: $color_secondary_dark;
$color_link_visited: $color_secondary_dark;
$color_link_ha: mix($color_primary,$color_near_white);

$color_bkg_near_white: #fbfcfc;
$color_bkg_light: #f1efee;
$color_bkg_med_light: #f1f5f7;
$color_bkg_med: #bed2dd;
$color_bkg_dark: #11374b;
$color_bkg_warning: #f8f0d0;
$color_bkg_grey: rgb(240,239,238);


$color_info: #4e6c7d;
$color_success: #536c08;
$color_warning: darken(#dbb216,20%);
$color_error: #bf0d3e;

// base sizes
$base_font_size: 1.5rem;
$width_gutter: 2.5rem;
$width_one_col: 550px;
$width_narrow_page: 850px;
$width_wide_page: 1200px;
